import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import Logo from './logo'

const useStyles = makeStyles(theme => ({
  navLink: {
    color: 'rebeccapurple',
    textDecoration: 'none',
    fontSize: '0.84rem',
    fontWeight: '700',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  primeNavLink: {
    color: 'rebeccapurple',
    fontSize: '0.84rem',
    textDecoration: 'none',
    marginLeft: theme.spacing(3),
    borderWidth: 2,
    borderColor: 'rebeccapurple',
    borderStyle: 'solid',
    borderRadius: 10,
    fontWeight: '700',
  },
  container: {
    color: 'rebeccapurple',
    display: 'flex',
    justifyContent: 'strech',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: ' 1rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  brand: {
    alignItems: 'center',
    textDecoration: 'none',
    marignLeft: 0,
    fontSize: '1.4rem',
    fontWeight: '700',
  },
  brandLogo: {
    width: 50,
  },
}))

const Header = ({ siteTitle }) => {
  const classes = useStyles()
  return (
    <header>
      <Container maxWidth="md" className={classes.container}>
        <Typography
          component={Link}
          to="/"
          style={{ display: 'flex' }}
          className={classes.brand}
        >
          <Logo className={classes.brandLogo} />
          {siteTitle}
        </Typography>
        <span style={{ flex: 1 }} />
        <Button component={Link} to="/" className={classes.navLink}>
          Services
        </Button>
        <Button component={Link} to="/team" className={classes.navLink}>
          Team
        </Button>
        <Button component={Link} to="/contact" className={classes.primeNavLink}>
          Schedule A Consultation
        </Button>
      </Container>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
