import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

export default function Copyright({ company }) {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`© ${new Date().getFullYear()} `}
      <Link color="inherit" to="/">
        {company}
      </Link>
      {' All rights reserved.'}
    </Typography>
  )
}
