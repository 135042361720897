import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Img from 'gatsby-image'

const useStyles = makeStyles(() => ({
  bg: {
    // position: 'absolute !important',
    width: 50,
    // marginTop: 100,
  },
}))

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      bannerBg: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
      }
    }
  `)

  return (
    <Img fluid={data.bannerBg.childImageSharp.fluid} className={className} />
  )
}

export default Image
