import React from 'react'
import { Link } from 'gatsby'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import Copyright from './copyright'
import Logo from './logo'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[800]
        : theme.palette.grey[200],
  },
  content: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(2),
    marginTop: 'auto',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  bottom: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  logoSection: {
    flex: 1,
    [theme.breakpoints.down('xs')]: { paddingLeft: 30 },
  },
  logo: {
    width: 100,
  },
  section: {
    paddingLeft: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  sectionTitle: {
    fontWeight: '600',
    color: '#6f6f6f',
    marginBottom: theme.spacing(2),
  },
}))

const FooterLink = ({ children, to }) => (
  <Typography
    component={Link}
    to={to}
    variant="body2"
    gutterBottom
    color="textSecondary"
    style={{ textDecoration: 'none' }}
  >
    {children}
  </Typography>
)
const SectionTitle = ({ children, classes }) => (
  <Typography variant="subtitle2" gutterBottom classes={classes}>
    {children}
  </Typography>
)

export default ({ siteTitle, company }) => {
  const classes = useStyles()
  return (
    <footer className={classes.root}>
      <Container maxWidth="md" className={classes.content}>
        <div className={classes.logoSection}>
          <Logo className={classes.logo} />
          <Typography variant="h5" gutterBottom>
            {siteTitle}
          </Typography>
        </div>
        <div className={classes.section}>
          <SectionTitle classes={{ root: classes.sectionTitle }}>
            Our Company
          </SectionTitle>
          <FooterLink to="">About Us</FooterLink>
        </div>
        <div className={classes.section}>
          <SectionTitle classes={{ root: classes.sectionTitle }}>
            Customer Service
          </SectionTitle>
          <FooterLink to="">Contact Us</FooterLink>
          <FooterLink to="">FAQs</FooterLink>
          <FooterLink to="">Blog</FooterLink>
        </div>
        <div className={classes.section}>
          <SectionTitle classes={{ root: classes.sectionTitle }}>
            Legal & Compliance
          </SectionTitle>
          <FooterLink to="">Terms of Service</FooterLink>
          <FooterLink to="">Privacy & Security</FooterLink>
          <FooterLink to="">Accessibiliy</FooterLink>
        </div>
      </Container>
      <Container maxWidth="md" className={classes.content}>
        <Copyright company={company} />
      </Container>
    </footer>
  )
}
